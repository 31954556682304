import { Component } from "@angular/core";
import { OnInit }    from "@angular/core";
import { Input }     from "@angular/core";
import { IMenu }     from "../models/menu";
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: "app-hover-menu-partial",
  templateUrl: "./hover-menu-partial.component.html",
  styleUrls: ["./hover-menu-partial.component.scss"]
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



export class HoverMenuPartialComponent implements OnInit {
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor() {}

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  @Input('menu') menu: IMenu;
  @Input('route') route:string;
  public menu_sports:IMenu;
  public slider_items:number = 2;
  public slide_config;

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
    if(document.body.scrollWidth <= 1340){
      this.slider_items = 2
      this.slide_config = {
        "slidesToShow": this.slider_items, 
        "slidesToScroll": this.slider_items,
        "dots": true,
        "prevArrow": false,
        "nextArrow": false,
      };
      
    }
    else{
      this.slide_config = {
        "slidesToShow": this.slider_items, 
        "slidesToScroll": this.slider_items,
        "dots": true,
        "prevArrow": false,
        "nextArrow": false,
      };
    }

  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnChanges(changes) {
   }
  
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  addSlide() {
    // this.slides.push({img: "http://placehold.it/350x150/777777"})
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  removeSlide() {
    // this.slides.length = this.slides.length - 1;
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  slickInit(e) {
  }
  
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  breakpoint(e) {
  }

  afterChange(e) {
  }

  beforeChange(e) {
  }

}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

