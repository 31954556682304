import { AdamiAwardComponent } from "./../components/pages/adami-award/adami-award.component";
import { PrivacyComponent } from "./../components/pages/privacy/privacy.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MainPageComponent } from "../components/pages/main-page/main-page.component";
import { NotFoundPageComponent } from "../components/helpers/not-found-page/not-found-page.component";
import { TvshowsPageComponent } from "../components/pages/tvshows-page/tvshows-page.component";
// import { TvshowsInnerPageComponent } from '../components/pages/tvshows-inner-page/tvshows-inner-page.component';
import { MobilReporterPageComponent } from "../components/pages/mobil-reporter-page/mobil-reporter-page.component";
import { ContactPageComponent } from "../components/pages/contact-page/contact-page.component";
import { PodcastsPageComponent } from "../components/pages/podcasts-page/podcasts-page.component";
import { SchedulePageComponent } from "../components/pages/schedule-page/schedule-page.component";
import { ScheduleDetailPageComponent } from "../components/pages/schedule-detail-page/schedule-detail-page.component";
import { NewsPageComponent } from "../components/pages/news-page/news-page.component";
import { NewsDetailPageComponent } from "../components/pages/news-detail-page/news-detail-page.component";
import { LiveTvComponent } from "../components/pages/live-tv/live-tv.component";
import { TvseriesPageComponent } from "../components/pages/tvseries-page/tvseries-page.component";
import { MoviesPageComponent } from "../components/pages/movies-page/movies-page.component";
import { MoviesDetailPageComponent } from "../components/pages/movies-detail-page/movies-detail-page.component";
import { TvshowsDetailPageComponent } from "../components/pages/tvshows-detail-page/tvshows-detail-page.component";
import { SportPageComponent } from "../components/pages/sport-page/sport-page.component";
import { SportDetailPageComponent } from "../components/pages/sport-page/sport-detail-page/sport-detail-page.component";
import { EurovisionPageComponent } from "../components/pages/eurovision-page/eurovision-page.component";
import { EurovisionNewsDetailComponent } from "../components/pages/eurovision-page/eurovision-news-detail/eurovision-news-detail.component";
import { LiveRadioPlayerComponent } from "../components/partials/live-radio-player/live-radio-player.component";
import { LiveRadioPageComponent } from "../components/pages/live-radio-page/live-radio-page.component";
import { TvseriesDetailPageComponent } from "../components/pages/tvseries-detail-page/tvseries-detail-page.component";
import { AboutPageComponent } from "../components/pages/about-page/about-page.component";
import { AboutCharterComponent } from "../components/pages/about-charter/about-charter.component";
import { AboutStructureComponent } from "../components/pages/about-structure/about-structure.component";
import { VoicekidsComponent } from "../components/pages/voice-kids/voice-kids.component";
import { MaskaComponent } from "../components/pages/maska/maska.component";
import { VoicekidsNewsDetailComponent } from "../components/pages/voice-kids/voicekids-news-detail/voicekids-news-detail.component";
import { MaskaNewsDetailComponent } from "../components/pages/maska/maska-news-detail/maska-news-detail.component";
import { AppealPageComponent } from "../components/pages/appeal-page/appeal-page.component";
import { VacancyComponent } from "../components/pages/vacancy/vacancy.component";
import {BroadcastingBoardPageComponent} from '../components/pages/broadcasting-board-page/broadcasting-board-page.component';
import {CarierPageComponent} from '../components/pages/carier-page/carier-page.component';
import {LiveVacancyPageComponent} from '../components/pages/live-vacancy-page/live-vacancy-page.component';
import {MskPageComponent} from '../components/pages/msk-page/msk-page.component';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
const appRoutes: Routes = [
  { path: "", component: MainPageComponent, pathMatch: "full" },
  { path: "tvshows", component: TvshowsPageComponent },
  { path: "tvshows/:id", component: TvshowsDetailPageComponent },
  { path: "tvseries", component: TvseriesPageComponent },
  { path: "tvseries/:id", component: TvseriesDetailPageComponent },
  { path: "mobil-reporter", component: MobilReporterPageComponent },
  { path: "contact", component: ContactPageComponent },
  { path: "about", component: AboutPageComponent },
  { path: "about/privacy", component: PrivacyComponent },
  { path: "about/adami", component: AdamiAwardComponent },
  // { path: 'about/charter', component: AboutCharterComponent},
  { path: "about/structure", component: AboutStructureComponent },
  {path: "about/yayim", component: BroadcastingBoardPageComponent },
  { path: "about/:id", component: AboutPageComponent },
  // { path: 'podcasts', component: PodcastsPageComponent},
  { path: "schedule", component: SchedulePageComponent },
  { path: "schedule/detail", component: ScheduleDetailPageComponent },
  { path: "schedule/detail/:day", component: ScheduleDetailPageComponent },
  { path: "news", component: NewsPageComponent },
  { path: "news/", component: NewsPageComponent },
  {
    path: "news/:news_id/detail/:detail_id",
    component: NewsDetailPageComponent,
  },
  { path: "movies", component: MoviesPageComponent },
  { path: "movies/:id", component: MoviesDetailPageComponent },
  // { path: 'sport', component: SportPageComponent},
  // { path: 'sport/:id', component: SportDetailPageComponent},
  { path: "eurovision", component: EurovisionPageComponent },
  { path: "eurovision/news/:id", component: EurovisionNewsDetailComponent },
  { path: "voice-azerbaijan", component: VoicekidsComponent },
  // { path: "voice-kids", component: VoicekidsComponent },
  { path: "maska", component: MaskaComponent },
  { path: "voice-kids/news/:id", component: VoicekidsNewsDetailComponent },
  { path: "maska/news/:id", component: MaskaNewsDetailComponent },
  { path: "appeal-page", component: AppealPageComponent },
  { path: "live-tv", component: LiveTvComponent },
  { path: "live-radio", component: LiveRadioPageComponent },
  { path: "vacancy", component: VacancyComponent },
  { path: "carier", component: CarierPageComponent },
  { path: "live-vacancy", component: LiveVacancyPageComponent },
  { path: "msk", component: MskPageComponent },
  { path: "**", component: NotFoundPageComponent },
];
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false, // <-- debugging purposes only
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export class AppRoutingModule {}
//ROUTE COMPONENTS  |||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
export const RoutingComponents = [
  MainPageComponent,
  NotFoundPageComponent,
  TvshowsPageComponent,
  // TvshowsInnerPageComponent,
  TvshowsDetailPageComponent,
  MobilReporterPageComponent,
  ContactPageComponent,
  PodcastsPageComponent,
  SchedulePageComponent,
  ScheduleDetailPageComponent,
  NewsPageComponent,
  NewsDetailPageComponent,
  LiveTvComponent,
  TvseriesPageComponent,
  TvseriesDetailPageComponent,
  MoviesPageComponent,
  MoviesDetailPageComponent,
  SportDetailPageComponent,
  SportPageComponent,
  EurovisionPageComponent,
  EurovisionNewsDetailComponent,
  AppealPageComponent,
  VoicekidsComponent,
  VoicekidsNewsDetailComponent,
  MaskaNewsDetailComponent,
  LiveRadioPlayerComponent,
  LiveRadioPageComponent,
  AboutPageComponent,
  BroadcastingBoardPageComponent,
  MaskaComponent
];
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
