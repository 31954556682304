import { Component, OnInit, AfterViewChecked, ViewChild, ElementRef  } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HelperService } from 'src/app/services/helper/helper.service';
import { CommonService, IBanner } from 'src/app/services/common/common.service';
import { Title } from '@angular/platform-browser';
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||
@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.scss']
})
//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||



export class MainPageComponent implements OnInit {

ngAfterViewInit() {
  setTimeout(() => {
    const videoElements = Array.from(document.querySelectorAll('video')) as HTMLVideoElement[];

    videoElements.forEach(videoElement => {
      videoElement.muted = true;
      videoElement.play().catch(error => console.log('Autoplay engellendi:', error));

      // Videonun bitiminde tekrar başlat
      videoElement.addEventListener('ended', () => {
        videoElement.currentTime = 0;
        videoElement.play();           // Videoyu tekrar başlat
      });
    });
  }, 1000);
}



//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  constructor(
    private SPINNER: NgxUiLoaderService,
    private HS: HelperService,
    private COMMON_SERVICE: CommonService,
    private TS:Title
  ) {
    this.TS.setTitle("İTV - Əsas səhifə");
    this.banners = this.COMMON_SERVICE.banners.getValue();
    this.COMMON_SERVICE.banners.subscribe(banners =>{
      this.banners = banners;
    })
  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  public banners:IBanner[];

  banner_1 = {
    img:'assets/images/iphone-banner.svg',
    url:'https://www.facebook.com/'
  };
  banner_2 = {
    img:'assets/images/eurovision-banner-3.svg',
    url:'https://www.facebook.com/'

  }

//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||

  ngOnInit() {
    this.SPINNER.start();
    this.HS.ScrollTo();
    setTimeout(() => { this.SPINNER.stop();}, 1200);
  }





}//||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||||


